import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
// eslint-disable-next-line import/namespace
import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Confirmation } from "./routes/Confirmation";

const App = lazy(() =>
  import("./routes/App").then((module) => ({
    default: module.App,
  })),
);

const embeds = Array.from(document.querySelectorAll(".js-srf-newsletter"));
embeds
  .map((embed) => embed as HTMLElement)
  .forEach((embed) => {
    // prevent duplicated initialization by multiple scripts on the same page
    if (embed.childElementCount > 0) {
      return;
    }
    embed.style.width = "100%";

    ReactDOM.createRoot(embed).render(
      <React.StrictMode>
        <HashRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/confirmation" element={<Confirmation />} />
          </Routes>
        </HashRouter>
      </React.StrictMode>,
    );
  });
