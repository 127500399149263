export const Description: React.FC<{
  text: string;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ text, setIsModalOpen }) => (
  <div className="n-text-base">
    {text}
    {setIsModalOpen && (
      <button
        className="n-ml-1 n-cursor-pointer n-font-medium n-text-red-100"
        onClick={() => setIsModalOpen(true)}>
        Erfahre mehr...
      </button>
    )}
  </div>
);
