import { Header } from "@Components/Header";
import Confirmed from "@Resources/images/confirmed.svg?react";

export const Confirmation = () => {
  return (
    <div className="n-mb-12 n-flex n-w-[628px] n-flex-col n-items-center n-justify-center dark:n-text-white">
      <Header
        icon={<Confirmed />}
        title="Wöchentlicher frischer Lesestoff im Literatur-Newsletter"
        description="Der Literatur-Newsletter bietet die perfekte Inspiration für das nächste Buch. Ausserdem wird jede Woche eine Schweizer Schriftstellerin oder ein Schweizer Schriftsteller in den Fokus gerückt."
      />
      <p className="n-mt-6 n-text-xs">
        Sie können sich jederzeit von den Newslettern abmelden, indem Sie im
        entsprechenden Mailing unter «Newsletter abbestellen» Ihre
        E-Mail-Adresse austragen.
      </p>
    </div>
  );
};
