import { Description } from "@Components/Header/Description";
import { Title } from "@Components/Header/Title";
import { IconWrapper } from "@Components/Icon/IconWrapper";
import React from "react";

type HeaderProps = {
  title: string;
  icon: React.ReactNode;
  description: string;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Header: React.FC<HeaderProps> = ({
  title,
  icon,
  description,
  setIsModalOpen,
}) => {
  return (
    <div className="n-text-black dark:n-text-white">
      <IconWrapper>{icon}</IconWrapper>
      <Title text={title} />
      <Description text={description} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};
